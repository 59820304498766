import React, { useEffect, useState } from 'react'
import '.././css/LandingPage.css'
import LOGO from "../assets/TYPOL.png"
import landingm from "../assets/landingm.mov"
import landing from "../assets/landing.MOV"
import landing3 from "../assets/2went6ex3.mov"
import landing1 from "../assets/landing1.MOV"
import landing2 from "../assets/landing2.MOV"
import logosmall from "../assets/logo.svg"
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

function LandingPage({ loadingPage }) {

  var width = window.innerWidth;

  if (loadingPage) return (
    <div className='loading__page'>

      <div className='loading__holder'>
        <div className='first__container'>
          <span>26</span>
          <span>01</span>
          <span>02</span>
          <span>03</span>
          <span>04</span>
          <span>05</span>
          <span>06</span>
          <span>07</span>
          <span>08</span>
          <span>09</span>
          <span>10</span>
          <span>11</span>
          <span>12</span>
          <span>13</span>
          <span>14</span>
          <span>15</span>
          <span>16</span>
          <span>17</span>
          <span>18</span>
          <span>19</span>
          <span>20</span>
          <span>20</span>
          <span>21</span>
          <span>22</span>
          <span>23</span>
          <span>24</span>
          <span>25</span>
          <span>26</span>
          <div className='top-gradient'></div>
          <div className='bottom-gradient'></div>
        </div>
        <div className='middle__container'>
          <span>/</span>
          <span>of</span>
          <span>/</span>
          <div className='top-gradient'></div>
          <div className='bottom-gradient'></div>
        </div>
        <div className='last__container'>
          <span>26</span>
        </div>

      </div>
    </div>
  );

  return (
    <Fade bottom duration={500}>
    <div className='landing__page'>
      <div className='landing__glass'>
        <img src={logosmall} width={100}/>
        <h1>COMING SOON</h1>
        <img src={logosmall} width={100}/>
      </div>
      <div className='hero__section'>
        <div class="hero" style={{minHeight: '100vh'}}>
          <video playsInline loading='eager' autoPlay muted loop alt='hero' src={width > 1000 ? landing3 : landingm} />
          <div className='hero__text'><Link to='/ourjourney'>Our Journey</Link></div>
        </div>
        
      </div>
    </div>
    </Fade>
  )
}

export default LandingPage


/**<div className='hero__text'>COLLECTIONS</div> */

/** <div class="hero" style={{minHeight: '100vh'}}>
          <video playsInline loading='eager' autoPlay muted loop alt='hero' src={width > 1000 ? landing1 : "https://videos.pexels.com/video-files/6010502/6010502-hd_1080_1920_25fps.mp4"} />
        </div>

        <div class="hero" style={{minHeight: '100vh'}}>
          <video playsInline loading='eager' autoPlay muted loop alt='hero' src={width > 1000 ? landing2 : "https://videos.pexels.com/video-files/4599004/4599004-hd_1080_1920_30fps.mp4"} />
        </div> */