import { createStorefrontApiClient } from '@shopify/storefront-api-client';

const client = createStorefrontApiClient({
    storeDomain: 'http://2went6ex.myshopify.com',
    apiVersion: '2024-04',
    publicAccessToken: '1105e1867365a0ca7ba8cbd7a657a966',
}); 

export default client;


/***/

/**const client = createStorefrontApiClient({
    storeDomain: 'http://checkout.ark8.net',
    apiVersion: '2024-04',
    publicAccessToken: '4b3558f42d03461426863f6f13bbd688',
}); */